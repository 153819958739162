import { Link } from "react-router-dom";

const Error404 = () => {
    return (
        <div className="contrainer-fluid text-center text-danger">
            <div className="container bg-light mt-5 rounded p-5">
                <p style={{ fontSize: "30vh" }} className="m-0 p-0">
                    404
                </p>
                <p style={{ fontSize: "5vh" }}>Page Not Found</p>
                <Link to="/" className="text-decoraction-none btn btn-danger">
                    Go Back Home
                </Link>
            </div>
        </div>
    );
};

export default Error404;
