import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Card, Typography } from "antd";
// Icon
import { SafetyOutlined } from "@ant-design/icons";
// Redux
import { LoginUser } from "../Redux/User";
import { InputBox } from "../Component/Input";
import { dispatchError } from "../utils";
import { TypeAnimation } from "react-type-animation";

const Login = () => {
    const { name } = useSelector((state) => state.user);
    const { userLoading } = useSelector((state) => state.loading);
    const { userError } = useSelector((state) => state.error);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message } = App.useApp();

    useEffect(() => {
        if (name !== null) navigate("/dashboard");
    }, [name]);

    const loginHandler = () => {
        dispatch(LoginUser({ email, password }, message, navigate));
    };
    return (
        <div className="vh-100 bg-warning overflow-auto">
            <div className="d-flex align-items-center justify-content-center my-auto flex-column my-5 py-5">
                <img src={"/brand.png"} alt="Study Leo" className="my-3" style={{ width: 200 }} />

                <Card style={{ minWidth: 350, zIndex: 1 }} className="shadow mb-3">
                    <Typography.Title level={1} className="text-center m-0 p-0">
                        <SafetyOutlined />
                    </Typography.Title>
                    <Typography.Title level={3} className="text-center m-0 mb-2">
                        Login
                    </Typography.Title>
                    <InputBox
                        label={"Email"}
                        type={"email"}
                        value={email}
                        setValue={setEmail}
                        loading={userLoading}
                        error={userError?.email}
                    />
                    <InputBox
                        label={"Password"}
                        type={"password"}
                        value={password}
                        setValue={setPassword}
                        loading={userLoading}
                        error={userError?.password}
                    />

                    <Button
                        type="primary"
                        block
                        className="mb-3"
                        size="large"
                        onClick={loginHandler}
                        disabled={userLoading}
                        loading={userLoading}
                    >
                        Login
                    </Button>
                    <Link
                        to="/register"
                        className="text-decoration-none mb-2"
                        onClick={() => dispatchError(dispatch, "user")}
                    >
                        <p className="text-center m-0">New Here? Register Now</p>
                    </Link>
                    <Link
                        to="/forgot"
                        className="text-decoration-none"
                        onClick={() => dispatchError(dispatch, "user")}
                    >
                        <p className="text-center m-0">Forgot Password? Click Here</p>
                    </Link>
                </Card>
                <TypeAnimation
                    sequence={[
                        "Empowering Your Goals, Together.",
                        500, // Waits 1s
                        "Turning Your Dreams into Reality, Hand in Hand.",
                        500, // Waits 2s
                        "Unlocking Your Potential, Every Step of the Way.",
                        () => {},
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{
                        fontSize: "14px",
                        display: "inline-block",
                        fontWeight: "bold",
                    }}
                />
            </div>
        </div>
    );
};

export default Login;
