import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Divider, Empty, Input, Popconfirm, Typography } from "antd";
// Icon
import { DeleteOutlined, ArrowRightOutlined } from "@ant-design/icons";
// Redux
import { DeleteNotes, FindAllNotes } from "../Redux/Notes";
import { NotesModal } from "../Component/model";

const Notes = () => {
    const { notesList } = useSelector((state) => state.dashboard);

    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FindAllNotes());
    }, []);

    return (
        <>
            <div className="d-md-flex align-items-center justify-content-between">
                <Typography.Title level={3} className="m-0">
                    Notes
                </Typography.Title>

                <div className="d-flex align-items-center">
                    <Input.Search
                        placeholder="Search"
                        allowClear
                        onSearch={(value) => dispatch(FindAllNotes(value))}
                    />
                    <Button type="primary" onClick={() => setModalOpen(true)} className="ms-2 px-4">
                        Add
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setEditMode(!editMode)}
                        danger
                        className="ms-2 px-4"
                    >
                        {editMode && "Turn Off"} Edit
                    </Button>
                </div>
            </div>
            <Divider className="my-2" />

            <div className="row g-4">
                {notesList?.length ? (
                    notesList.map((ele, index) => {
                        return <NotesCard data={ele} key={index} editMode={editMode} />;
                    })
                ) : (
                    <Empty description={"No Notes yet, try making one."} />
                )}
            </div>

            <NotesModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
    );
};
export const NotesCard = ({ data, editMode }) => {
    const { message } = App.useApp();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Popconfirm
            title="Delete this Notes?"
            description={`Are you sure to delete notes named "${data?.title}" ?`}
            onConfirm={() => dispatch(DeleteNotes({ notesId: data?._id }, message))}
            onCancel={() => {}}
            okText="DELETE"
            cancelText="CANCEL"
        >
            <div
                className="col-12 col-lg-6 col-xxl-4"
                onClick={() => {
                    if (editMode === null) navigate(`/dashboard/notes/${data?._id}`);
                    else if (!editMode) navigate(`${data?._id}`);
                }}
            >
                <div className="border rounded p-2 d-flex align-items-center justify-content-between ">
                    <Typography.Title level={5} className="m-0 p-0">
                        {data?.title}
                    </Typography.Title>
                    <Typography.Text className="m-0 p-0">
                        {editMode ? (
                            <DeleteOutlined width={30} height={30} />
                        ) : (
                            <ArrowRightOutlined width={30} height={30} />
                        )}
                    </Typography.Text>
                </div>
            </div>
        </Popconfirm>
    );
};

export default Notes;
