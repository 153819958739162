import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllDashboard =
    (nometa = false, search = "") =>
    async (dispatch) => {
        dispatchLoading(dispatch, "dashboard", true);
        axios
            .get(`${url}/dashboard/all`, {
                params: { search, nometa: nometa ? "1" : "0" },
                withCredentials: true,
            })
            .then((result) => {
                dispatch({ type: "LOAD_DASHBOARD", payload: result?.data?.data });
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "dashboard", false));
    };

export const AddDashboard = (data, message, closeHandler) => async (dispatch) => {
    dispatchLoading(dispatch, "dashboard", true);
    axios
        .post(`${url}/dashboard`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "dashboard");
            if (result?.data?.success) {
                message.success("Dashboard Created");
                dispatch(FindAllDashboard(true));
                closeHandler();
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "dashboard", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "dashboard", false));
};

export const UpdateDashboard = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "dashboard", true);
    axios
        .put(`${url}/dashboard`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "dashboard");
            dispatch(FindAllDashboard(true));
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "dashboard", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "dashboard", false));
};

export const DeleteDashboard = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "dashboard", true);
    axios
        .delete(`${url}/dashboard`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "dashboard");
            if (result?.data?.success) {
                message.success("Dashboard Deleted");
                dispatch(FindAllDashboard(true));
            } else {
                message.error("Unable to delete Dashboard");
            }
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "dashboard", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "dashboard", false));
};
