import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllBugs = () => async (dispatch) => {
    dispatchLoading(dispatch, "bugs", true);
    axios
        .get(`${url}/bugs`, { withCredentials: true })
        .then((result) => {
            dispatch({ type: "LOAD_BUGS", payload: result?.data?.data });
        })
        .catch((err) => console.log(err))
        .finally(() => dispatchLoading(dispatch, "bugs", false));
};

export const AddBugs = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "bugs", true);
    axios
        .post(`${url}/bugs`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "bugs");
            if (result?.data?.success) {
                message.success("Bug has been reported");
                dispatch(FindAllBugs(data?.dashboardId));
            } else {
                message.error("Unable to report bugs");
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "bugs", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "bugs", false));
};

export const UpdateBugs = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "bugs", true);
    axios
        .put(`${url}/bugs`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "bugs");
            dispatch(FindAllBugs());
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "bugs", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "bugs", false));
};

export const DeleteBugs = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "bugs", true);
    axios
        .delete(`${url}/bugs`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "bugs");
            dispatch(FindAllBugs());
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "bugs", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "bugs", false));
};
