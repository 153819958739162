import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Card, Typography } from "antd";
// Redux

import { InputBox } from "../Component/Input";
import { dispatchError } from "../utils";
import { ResetUser } from "../Redux/User";

const Reset = () => {
    const { token } = useParams();

    const { name } = useSelector((state) => state.user);
    const { userLoading } = useSelector((state) => state.loading);
    const { userError } = useSelector((state) => state.error);

    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message } = App.useApp();

    useEffect(() => {
        if (name !== null) navigate("/dashboard");
    }, [name]);

    const resetHandler = () => {
        dispatch(ResetUser({ token, password }, message, navigate));
    };
    return (
        <div className="vh-100 bg-warning overflow-auto">
            <div className="d-flex align-items-center justify-content-center my-auto flex-column my-5 py-5">
                <img src={"/brand.png"} alt="Study Leo" className="my-3" style={{ width: 200 }} />

                <Card style={{ minWidth: 350, zIndex: 1 }} className="shadow mb-3">
                    <Typography.Title level={3} className="text-center">
                        Reset Password
                    </Typography.Title>
                    <InputBox
                        label={"Password"}
                        type={"password"}
                        value={password}
                        setValue={setPassword}
                        loading={userLoading}
                        error={userError?.password}
                    />

                    <Button
                        type="primary"
                        block
                        className="mb-3"
                        size="large"
                        onClick={resetHandler}
                        disabled={userLoading}
                        loading={userLoading}
                    >
                        Submit
                    </Button>
                </Card>
            </div>
        </div>
    );
};

export default Reset;
