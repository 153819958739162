import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Table, Tag, Typography } from "antd";
import dayjs from "dayjs";
// Icons
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
// Redux
import { DeleteExamSchedule, FindAllExamSchedule } from "../Redux/ExamSchedule";
import { ExamScheduleModal } from "../Component/model";

const ExamSchedule = () => {
    const { examScheduleList } = useSelector((state) => state.dashboard);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FindAllExamSchedule());
    }, []);
    return (
        <div>
            {/* Header Section */}
            <div className="d-md-flex align-items-center justify-content-between">
                <Typography.Title level={3} className="m-0 mb-3 mb-md-0">
                    Exam Schedule
                </Typography.Title>
                <Button type="primary" className="px-4" onClick={() => setModalOpen(true)}>
                    Add
                </Button>
            </div>
            {/* Header Section */}

            <Divider className="my-2" />

            {/* Exam Content */}
            <Table
                pagination={false}
                columns={[
                    { title: "Name", dataIndex: "name", key: "name" },
                    {
                        title: "Type",
                        dataIndex: "type",
                        key: "type",
                        render: (value, _, _1) => {
                            return (
                                <Tag color="orange-inverse" className="text-capitalize">
                                    {value}
                                </Tag>
                            );
                        },
                    },
                    {
                        title: "Date",
                        dataIndex: "date",
                        key: "date",
                        render: (value, _, _1) => {
                            return dayjs(value).format("dddd, DD MMMM YYYY");
                        },
                    },
                    {
                        title: "Time",
                        dataIndex: "date",
                        key: "time",
                        render: (value, _, _1) => {
                            return dayjs(value).format("hh:mm A");
                        },
                    },
                    { title: "Venue", dataIndex: "venue", key: "venue" },
                    { title: "Weightage", dataIndex: "weightage", key: "weightage" },
                    {
                        title: "Action",
                        dataIndex: "action",
                        key: "action",
                        render: (value, record, _1) => {
                            return (
                                <div className="d-flex align-items-center">
                                    <Button
                                        type="primary"
                                        className="d-flex align-items-center me-2"
                                        onClick={() => {
                                            setModalData(record);
                                            setModalOpen(true);
                                        }}
                                    >
                                        <EditOutlined className="m-0 p-0" />
                                    </Button>
                                    <Button
                                        className="d-flex align-items-center"
                                        type="primary"
                                        onClick={() =>
                                            dispatch(
                                                DeleteExamSchedule({ examScheduleId: value?._id })
                                            )
                                        }
                                        danger
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
                dataSource={examScheduleList}
            />
            {/* Exam Content */}
            <ExamScheduleModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                modalData={modalData}
                setModalData={setModalData}
            />
        </div>
    );
};

export default ExamSchedule;
