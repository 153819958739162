import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { TypeAnimation } from "react-type-animation";
// Icons
import {
    HeartFilled,
    ProjectOutlined,
    FileTextOutlined,
    BookOutlined,
    CalendarOutlined,
    BugOutlined,
    BulbOutlined,
    StarOutlined,
} from "@ant-design/icons";

const Index = () => {
    const navigate = useNavigate();
    return (
        <div className="container-fluid bg-white">
            {/* Header */}
            <header className="text-center mt-3 mb-5 pb-3 border-bottom">
                <Link to={"https://study.leomalay.com/"} className="text-dark text-decoration-none">
                    <h1 style={{ fontFamily: "KaushanScript" }}>StudyLeo</h1>
                </Link>
            </header>
            {/* Header */}
            {/* Landing Page */}
            <div className="container">
                <div className="row">
                    <div className="col-md-6 order-2 order-md-1">
                        <div style={{ height: "18vw" }} className="d-none d-md-block">
                            <TypeAnimation
                                sequence={[
                                    "Empower Your Learning Journey: Study Smarter with Our Dashboard",
                                    500, // Waits 1s
                                    "Study Better, Not Harder: Your Personalized Study Dashboard Solution",
                                    500, // Waits 2s
                                    "Elevate Your Education: Simplify Studies with Our Dashboard",
                                    () => {},
                                ]}
                                wrapper="span"
                                cursor={true}
                                repeat={Infinity}
                                style={{
                                    fontSize: "3vw",
                                    display: "inline-block",
                                    fontWeight: "bold",
                                }}
                            />
                        </div>
                        <div style={{ height: "200px" }} className="d-block d-md-none">
                            <TypeAnimation
                                sequence={[
                                    "Empower Your Learning Journey: Study Smarter with Our Dashboard",
                                    500, // Waits 1s
                                    "Study Better, Not Harder: Your Personalized Study Dashboard Solution",
                                    500, // Waits 2s
                                    "Elevate Your Education: Simplify Studies with Our Dashboard",
                                    () => {},
                                ]}
                                wrapper="span"
                                cursor={true}
                                repeat={Infinity}
                                style={{
                                    fontSize: "30px",
                                    display: "inline-block",
                                    fontWeight: "bold",
                                }}
                            />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                            <Button
                                type="primary"
                                size="large"
                                className="w-50 my-2"
                                onClick={() => navigate("/login")}
                            >
                                Start Adventure
                            </Button>
                            <p>
                                Don't worry, its <span className="fw-bold">Free</span>,{" "}
                                <span className="fw-bold">Safe</span> and{" "}
                                <span className="fw-bold">Secure</span>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <img src={"/landing.png"} alt={"Landing"} className="w-100" />
                    </div>
                </div>
            </div>
            {/* Landing Page */}

            {/* Feature Page */}
            <div className="container-fluid mt-5 py-5 bg-light">
                <div className="row">
                    <div className="col-12 col-xl-6 p-3">
                        <img src={"landing3.png"} alt={"Landing 3"} className="w-100" />
                    </div>
                    <div className="col-12 col-xl-6 p-3 text-center">
                        <div className="row">
                            {[
                                {
                                    icon: <ProjectOutlined />,
                                    title: "Dashboard",
                                    description:
                                        "Keep track of pending and completed tasks. These are linked to your assignments and notes.",
                                },
                                {
                                    icon: <FileTextOutlined />,
                                    title: "Assignments",
                                    description:
                                        "Having all the assignments in one place helps to stay ahead of deadlines.",
                                },
                            ].map((ele, index) => {
                                return (
                                    <div className="col-12 p-3 my-3" key={index}>
                                        <h1>{ele?.icon}</h1>
                                        <h3 className="my-1">{ele?.title}</h3>
                                        <h5>{ele?.description}</h5>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-xl-6 p-3 order-2 order-md-1 text-center">
                        <div className="row">
                            {[
                                {
                                    icon: <BookOutlined />,
                                    title: "Notes",
                                    description:
                                        "Make notes on the go with the built-in powerful text editor which also allows you to embed videos.",
                                },
                                {
                                    icon: <CalendarOutlined />,
                                    title: "Exam Schedule",
                                    description:
                                        "Add your upcoming exams here, so you always know whats coming up next.",
                                },
                            ].map((ele, index) => {
                                return (
                                    <div className="col-12 p-3 my-3" key={index}>
                                        <h1>{ele?.icon}</h1>
                                        <h3 className="my-1">{ele?.title}</h3>
                                        <h5>{ele?.description}</h5>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="col-12 col-xl-6 p-3 order-1 order-md-2">
                        <img src={"landing2.png"} alt={"Landing 2"} className="w-100" />
                    </div>
                </div>
            </div>
            {/* Feature Page */}

            {/* Other Feature */}
            <div className="container my-5">
                <div className="row">
                    {[
                        {
                            icon: <BulbOutlined />,
                            title: "Dark Mode",
                            description:
                                "Relax your eyes during late night studies with Dark Mode feature",
                        },
                        {
                            icon: <BugOutlined />,
                            title: "Bug Report",
                            description:
                                "Notice something not quite right? Report it using the bug report feature, and I will make sure it gets fixed",
                        },
                        {
                            icon: <StarOutlined />,
                            title: "Feedback",
                            description:
                                "I am constantly making an effort to improve this project, your feedback is valuable to me.",
                        },
                    ].map((ele, index) => {
                        return (
                            <div className="col-12 col-md-4 p-3 text-center" key={index}>
                                <h1>{ele?.icon}</h1>
                                <h4 className="my-3">{ele?.title}</h4>
                                <h6>{ele?.description}</h6>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* Other Feature */}

            {/* Parent Website Info */}
            <div className="container-fluid text-center py-5 bg-light">
                <h3 className="my-5">
                    If you wish to see more such projects, do visit{" "}
                    <Link
                        to="https://www.leomalay.com/"
                        className="text-decoration-underline text-dark fst-italic"
                    >
                        leomalay.com
                    </Link>
                </h3>
            </div>
            {/* Parent Website Info */}

            {/* Footer */}
            <footer className="text-center fw-bold my-3">
                <p>
                    Developed with <HeartFilled /> by Malay Bhavsar
                </p>
            </footer>
            {/* Footer */}
        </div>
    );
};

export default Index;
