import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Card, Typography } from "antd";
// Redux
import { ForgotUser } from "../Redux/User";
import { InputBox } from "../Component/Input";
import { dispatchError } from "../utils";

const Forgot = () => {
    const { name } = useSelector((state) => state.user);
    const { userLoading } = useSelector((state) => state.loading);
    const { userError } = useSelector((state) => state.error);

    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message } = App.useApp();

    useEffect(() => {
        if (name !== null) navigate("/dashboard");
    }, [name]);

    const forgotHandler = () => {
        dispatch(ForgotUser({ email }, message, showConfirmation));
    };

    const showConfirmation = () => {
        setEmailConfirm(true);
    };
    return (
        <div className="vh-100 bg-warning overflow-auto">
            <div className="d-flex align-items-center justify-content-center my-auto flex-column my-5 py-5">
                <img src={"/brand.png"} alt="Study Leo" className="my-3" style={{ width: 200 }} />

                <Card style={{ minWidth: 350, zIndex: 1 }} className="shadow mb-3">
                    {!emailConfirm ? (
                        <>
                            <Typography.Title level={3} className="text-center">
                                Forgot Password?
                            </Typography.Title>
                            <InputBox
                                label={"Email"}
                                type={"email"}
                                value={email}
                                setValue={setEmail}
                                loading={userLoading}
                                error={userError?.email}
                            />

                            <Button
                                type="primary"
                                block
                                className="mb-3"
                                size="large"
                                onClick={forgotHandler}
                                disabled={userLoading}
                                loading={userLoading}
                            >
                                Send Link
                            </Button>
                        </>
                    ) : (
                        <>
                            <div className="text-center">
                                <img
                                    src={"/img/forgotpass.png"}
                                    alt="forgot password"
                                    height={200}
                                />
                            </div>
                            <Typography.Title level={3} className="text-center">
                                Email Sent
                            </Typography.Title>
                            <Typography.Text className="text-center mb-5">
                                Check your inbox and spam folder. Click on the button in the link to
                                change your password.
                            </Typography.Text>
                        </>
                    )}
                </Card>
            </div>
        </div>
    );
};

export default Forgot;
