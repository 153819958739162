import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Form, Input, Popconfirm, Typography } from "antd";
// Redux
import { UpdateUser } from "../Redux/User";

const Profile = () => {
    const user = useSelector((state) => state.user);

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        if (user) {
            setName(user?.name);
            setEmail(user?.email);
        }
    }, [user]);

    const dispatch = useDispatch();

    return (
        <div>
            {/* Header Section */}
            <Typography.Title level={3} className="m-0">
                Profile
            </Typography.Title>
            {/* Header Section */}

            <Divider className="my-2" />

            {/* Profile Content */}
            <Form layout="vertical" style={{ width: 400, margin: "auto" }} size={"large"}>
                <Form.Item label="Name">
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Item>
                <Form.Item label="Email">
                    <Input value={email} onChange={(e) => setEmail(e.target.value)} />
                </Form.Item>
                <Button type="primary" block onClick={() => dispatch(UpdateUser({ email, name }))}>
                    Save
                </Button>
                <Popconfirm
                    title="Delete Account?"
                    description="Are you sure to delete your account?"
                    onConfirm={() => {}}
                    onCancel={() => {}}
                    okText="DELETE"
                    cancelText="CANCEL"
                >
                    <Button type="default" block danger className="mt-3 d-none">
                        Delete Account
                    </Button>
                </Popconfirm>
            </Form>
            {/* Profile Content */}
        </div>
    );
};

export default Profile;
