import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Layout, Menu, theme } from "antd";
import dayjs from "dayjs";
// Icons
import {
    UserOutlined,
    ProjectOutlined,
    LogoutOutlined,
    BugOutlined,
    SmileOutlined,
    StarOutlined,
} from "@ant-design/icons";
// Redux
import { LogoutUser } from "../Redux/User";

const { Header, Content, Sider } = Layout;

const items = [
    { key: "", icon: <ProjectOutlined />, label: "Dashboard" },
    { key: "user", icon: <UserOutlined />, label: "User" },
    { key: "bugs", icon: <BugOutlined />, label: "Bug Report" },
    { key: "feedback", icon: <StarOutlined />, label: "Feedback" },
    { key: "logout", icon: <LogoutOutlined />, label: "Logout" },
];

const AdminIndex = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { name, admin } = useSelector((state) => state.user);
    const { message } = App.useApp();

    const [collapsed, setCollapsed] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuClickHanlder = ({ item, key, keyPath, domEvent }) => {
        if (key === "logout") dispatch(LogoutUser(message, navigate));
        else navigate(key);
    };

    useEffect(() => {
        if (admin === false) navigate("/", { replace: true });
    }, [admin]);

    return (
        <Layout style={{ minHeight: "100vh", overflow: "none" }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical" />
                <div className="text-white d-flex align-items-center justify-content-center mt-3">
                    <Link to="/dashboard">
                        <img
                            src={"/brand-no-bg.png"}
                            alt="Study Leo"
                            style={{ width: collapsed ? 70 : 150 }}
                        />
                    </Link>
                </div>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={window.location?.pathname?.split("/")?.[0]}
                    mode="inline"
                    items={items}
                    style={{ marginTop: 20 }}
                    onClick={menuClickHanlder}
                />
            </Sider>
            <Layout style={{ height: "100vh" }}>
                <Header
                    style={{
                        margin: 0,
                        background: colorBgContainer,
                    }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mt-3 pt-2">
                            <SmileOutlined className="me-2" />
                            {name}
                        </h6>
                        <h6 className="mt-3 pt-2">
                            {dayjs(new Date()).format("dddd, DD MMMM YYYY")}
                        </h6>
                    </div>
                </Header>
                <Content style={{ margin: "16px", height: "100%" }}>
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            background: colorBgContainer,
                            padding: 24,
                        }}
                    >
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};
export default AdminIndex;
