import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllNotes =
    (search = "", dashboardId = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "notes", true);
        axios
            .get(`${url}/notes/all`, { params: { search, dashboardId }, withCredentials: true })
            .then((result) => {
                dispatch({ type: "LOAD_NOTES", payload: result?.data?.data });
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "notes", false));
    };

export const AddNotes = (data, message, cb) => async (dispatch) => {
    dispatchLoading(dispatch, "notes", true);
    axios
        .post(`${url}/notes`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "notes");
            if (result?.data?.success) {
                message.success("Notes Created");
                cb();
                dispatch(FindAllNotes());
            } else {
                message.error("Unable to create notes");
            }
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "notes", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "notes", false));
};

export const UpdateNotes = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "notes", true);
    axios
        .put(`${url}/notes`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "notes");
            if (result.data?.success) message.success(result?.data?.msg);
            else message.error(result?.data?.msg);
            dispatch(FindAllNotes());
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "notes", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "notes", false));
};

export const DeleteNotes = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "notes", true);
    axios
        .delete(`${url}/notes`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "notes");
            if (result?.data?.success) {
                message.success("Notes Deleted");
                dispatch(FindAllNotes(data?.dashboardId));
            } else {
                message.error("Unable to delete notes");
            }
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "notes", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "notes", false));
};
