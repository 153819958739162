import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Divider, Empty, Form, Input, Modal, Popconfirm, Typography } from "antd";
// Icons
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
// Redux
import { AddDashboard, DeleteDashboard, FindAllDashboard } from "../Redux/Dashboard";
import { dispatchError } from "../utils";

const Dashboard = () => {
    const { dashboardList } = useSelector((state) => state.dashboard);

    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [search, setSearch] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FindAllDashboard(true));
    }, []);
    return (
        <>
            {/* Header Section */}
            <div className="d-md-flex align-items-center justify-content-between">
                <Typography.Title level={3} className="m-0 mb-3 mb-md-0">
                    {editMode && "Edit "}Dashboard
                </Typography.Title>
                <div className="d-flex align-items-center">
                    <Input.Search
                        placeholder="Search"
                        allowClear
                        onSearch={(value) => {
                            setSearch(value);
                            dispatch(FindAllDashboard(true, value));
                        }}
                    />
                    <Button type="primary" className="ms-2 px-4" onClick={() => setModalOpen(true)}>
                        Add
                    </Button>
                    <Button
                        type="primary"
                        className="ms-2 px-4"
                        danger
                        onClick={() => setEditMode(!editMode)}
                    >
                        {editMode && "Turn off "}Edit
                    </Button>
                </div>
            </div>
            {/* Header Section */}

            <Divider className="my-2" />

            {/* Dashboard Content */}
            <div className="row g-4">
                {dashboardList && dashboardList?.length > 0 ? (
                    dashboardList.map((ele, index) => {
                        return <DashboardCard data={ele} key={index} editMode={editMode} />;
                    })
                ) : (
                    <Empty
                        description={
                            search?.length > 0
                                ? "No such dashboard found. Try searching for something else"
                                : "No dashboards yet, try creating one."
                        }
                    />
                )}
            </div>
            {/* Dashboard Content */}

            <NewDashboardModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
    );
};

const DashboardCard = ({ data, editMode }) => {
    const { message } = App.useApp();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return (
        <Popconfirm
            title="Delete this dashboard?"
            description={`Are you sure to delete dashboard named "${data?.name}" ?`}
            onConfirm={() => dispatch(DeleteDashboard({ dashboardId: data?._id }, message))}
            onCancel={() => {}}
            okText="DELETE"
            cancelText="CANCEL"
        >
            <div
                className="col-12 col-lg-6 col-xxl-4"
                onClick={() => {
                    if (!editMode) {
                        navigate(`${data?._id}`);
                    }
                }}
            >
                <div className="border rounded p-2 d-flex align-items-center justify-content-between ">
                    <Typography.Title level={5} className="m-0 p-0">
                        {data?.name}
                    </Typography.Title>
                    <Typography.Text className="m-0 p-0">
                        {editMode ? (
                            <DeleteOutlined width={30} height={30} />
                        ) : (
                            <ArrowRightOutlined width={30} height={30} />
                        )}
                    </Typography.Text>
                </div>
            </div>
        </Popconfirm>
    );
};

const NewDashboardModal = ({ modalOpen, setModalOpen }) => {
    const { message } = App.useApp();
    const { dashboardLoading } = useSelector((state) => state.loading);
    const { dashboardError } = useSelector((state) => state.error);

    const [name, setName] = useState("");

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            AddDashboard({ name }, message, () => {
                setName("");
                setModalOpen(false);
                dispatchError(dispatch, "dashboard");
            })
        );
    };
    const cancelHandler = (e) => {
        e.preventDefault();
        setName("");
        setModalOpen(false);
        dispatchError(dispatch, "dashboard");
    };

    return (
        <Modal
            title="Create New Dashboard"
            centered
            open={modalOpen}
            onOk={submitHandler}
            onCancel={cancelHandler}
            closable={false}
            maskClosable={false}
            okText={"Create"}
        >
            <Form layout="vertical" size={"large"}>
                <Form.Item label="Name (Can be a Project Name, Subject Name)">
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        disabled={dashboardLoading}
                    />
                    <span className="small m-0 text-danger">&nbsp;{dashboardError?.name}</span>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default Dashboard;
