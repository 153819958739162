import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { App, DatePicker, Form, Input, Modal, Select, Typography } from "antd";

// Redux
import { AddAssignment } from "../Redux/Assignment";
import { AddNotes } from "../Redux/Notes";
import { AddTask } from "../Redux/Task";
import { AddExamSchedule, UpdateExamSchedule } from "../Redux/ExamSchedule";

import { dispatchError } from "../utils";

export const AssignmentModal = ({ modalOpen, setModalOpen, def_dashboardId }) => {
    const { message } = App.useApp();

    const { dashboardList } = useSelector((state) => state.dashboard);
    const { assignmentError } = useSelector((state) => state.error);

    const [title, setTitle] = useState("");
    const [dashboardId, setDashboardId] = useState(def_dashboardId ? def_dashboardId : null);
    const [description, setDescription] = useState("");
    const [assignedOn, setAssignedOn] = useState(undefined);
    const [dueOn, setDueOn] = useState(undefined);

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            AddAssignment(
                {
                    title,
                    description,
                    dashboardId,
                    dueOn: dueOn === undefined ? dayjs(undefined).format("YYYY-MM-DD") : dueOn,
                    assignedOn:
                        assignedOn === undefined
                            ? dayjs(undefined).format("YYYY-MM-DD")
                            : assignedOn,
                },
                message,
                () => {
                    setTitle("");
                    setDescription("");
                    setDashboardId(null);
                    setAssignedOn(undefined);
                    setDueOn(undefined);
                    setModalOpen(false);
                }
            )
        );
    };
    const cancelHandler = (e) => {
        e.preventDefault();
        setTitle("");
        setDescription("");
        setDashboardId(null);
        setAssignedOn(undefined);
        setDueOn(undefined);
        setModalOpen(false);
        dispatchError(dispatch, "assignment");
    };

    return (
        <Modal
            centered
            open={modalOpen}
            onOk={submitHandler}
            onCancel={cancelHandler}
            closable={false}
            maskClosable={false}
            okText={"Create"}
        >
            <Typography.Title level={3} className="text-center">
                Assignment
            </Typography.Title>
            <Form layout="vertical">
                <Form.Item label="Title">
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                    <span className="small m-0 text-danger">&nbsp;{assignmentError?.title}</span>
                </Form.Item>
                <Form.Item label="Description">
                    <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <span className="small m-0 text-danger">
                        &nbsp;{assignmentError?.description}
                    </span>
                </Form.Item>
                <Form.Item label="Associated Dashboard">
                    <Select
                        value={dashboardId}
                        onChange={setDashboardId}
                        options={[{ value: null, label: "None of Listed" }].concat(
                            dashboardList?.map((ele) => {
                                return { value: ele?._id, label: ele?.name };
                            })
                        )}
                    />
                </Form.Item>
                <div className="row">
                    <div className="col-6">
                        <Form.Item label="Assigned Date">
                            <DatePicker
                                value={dayjs(assignedOn)}
                                format={"dddd, DD MMM YYYY"}
                                onChange={(value, dateString) => {
                                    if (value === null) setAssignedOn(undefined);
                                    else setAssignedOn(dayjs(value).format("YYYY-MM-DD"));
                                }}
                                block
                            />
                        </Form.Item>
                    </div>
                    <div className="col-6">
                        <Form.Item label="Due Date">
                            <DatePicker
                                value={dayjs(dueOn)}
                                format={"dddd, DD MMM YYYY"}
                                block
                                onChange={(value, dateString) => {
                                    if (value === null) setDueOn(undefined);
                                    else setDueOn(dayjs(value).format("YYYY-MM-DD"));
                                }}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export const NotesModal = ({ modalOpen, setModalOpen, def_dashboardId }) => {
    const { message } = App.useApp();

    const { dashboardList } = useSelector((state) => state.dashboard);
    const { notesError } = useSelector((state) => state.error);

    const [title, setTitle] = useState("");
    const [dashboardId, setDashboardId] = useState(def_dashboardId ? def_dashboardId : null);

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(
            AddNotes({ title, dashboardId }, message, () => {
                setTitle("");
                setDashboardId(null);
                setModalOpen(false);
            })
        );
    };
    const cancelHandler = (e) => {
        e.preventDefault();
        setTitle("");
        setDashboardId(null);
        dispatchError(dispatch, "assignment");
        setModalOpen(false);
    };

    return (
        <Modal
            centered
            open={modalOpen}
            onOk={submitHandler}
            onCancel={cancelHandler}
            closable={false}
            maskClosable={false}
            okText={"Create"}
        >
            <Typography.Title level={3} className="text-center">
                Notes
            </Typography.Title>
            <Form layout="vertical">
                <Form.Item label="Title">
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                    <span className="small m-0 text-danger">&nbsp;{notesError?.title}</span>
                </Form.Item>
                <Form.Item label="Associated Dashboard">
                    <Select
                        value={dashboardId}
                        onChange={setDashboardId}
                        options={[{ value: null, label: "None of Listed" }].concat(
                            dashboardList?.map((ele) => {
                                return { value: ele?._id, label: ele?.name };
                            })
                        )}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const TaskModal = ({ modalOpen, setModalOpen, dashboardId }) => {
    const { message } = App.useApp();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(AddTask({ title, description, dashboardId }, message));
        setTitle("");
        setDescription("");
        setModalOpen(false);
    };
    const cancelHandler = (e) => {
        e.preventDefault();
        setTitle("");
        setDescription("");
        setModalOpen(false);
    };

    return (
        <Modal
            centered
            open={modalOpen}
            onOk={submitHandler}
            onCancel={cancelHandler}
            closable={false}
            maskClosable={false}
            okText={"Create"}
        >
            <Typography.Title level={3} className="text-center">
                Task
            </Typography.Title>
            <Form layout="vertical" size={"large"}>
                <Form.Item label="Title">
                    <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Item>
                <Form.Item label="Description">
                    <Input.TextArea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export const ExamScheduleModal = ({
    modalOpen,
    setModalOpen,
    modalData,
    setModalData,
    def_dashboardId,
}) => {
    const { message } = App.useApp();
    const { dashboardList } = useSelector((state) => state.dashboard);

    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [date, setDate] = useState(undefined);
    const [venue, setVenue] = useState("");
    const [dashboardId, setDashboardId] = useState(def_dashboardId ? def_dashboardId : null);
    const [weightage, setWeightage] = useState("");

    useEffect(() => {
        if (modalData !== undefined && modalData !== null) {
            setName(modalData?.name);
            setType(modalData?.type);
            setDate(modalData?.date);
            setVenue(modalData?.venue);
            setDashboardId(modalData?.dashboardId);
            setWeightage(modalData?.weightage);
        }
    }, [modalOpen]);

    const dispatch = useDispatch();

    const submitHandler = (e) => {
        e.preventDefault();
        if (modalData !== undefined)
            dispatch(
                UpdateExamSchedule(
                    {
                        examScheduleId: modalData?._id,
                        name,
                        type,
                        date:
                            date === undefined ? dayjs(undefined).format("YYYY-MM-DD HH:mm") : date,
                        dashboardId,
                        venue,
                        weightage,
                    },
                    message
                )
            );
        else
            dispatch(
                AddExamSchedule(
                    {
                        name,
                        type,
                        date:
                            date === undefined ? dayjs(undefined).format("YYYY-MM-DD HH:mm") : date,
                        dashboardId,
                        venue,
                        weightage,
                    },
                    message
                )
            );
        setName("");
        setType("");
        setDate(undefined);
        setDashboardId(null);
        setVenue("");
        setWeightage("");
        setModalData(undefined);
        setModalOpen(false);
    };
    const cancelHandler = (e) => {
        e.preventDefault();
        setName("");
        setType("");
        setDate(undefined);
        setDashboardId(null);
        setVenue("");
        setWeightage("");
        if (modalData) setModalData(undefined);
        setModalOpen(false);
    };

    return (
        <Modal
            centered
            open={modalOpen}
            onOk={submitHandler}
            onCancel={cancelHandler}
            closable={false}
            maskClosable={false}
            okText={`${modalData !== undefined ? "Update" : "Add"}`}
        >
            <Form layout="vertical">
                <Form.Item label="Name">
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </Form.Item>
                <Form.Item label="Type">
                    <Select
                        value={type}
                        onChange={setType}
                        options={[
                            {
                                value: "exam",
                                label: "Exam",
                            },
                            {
                                value: "test",
                                label: "Test",
                            },
                            {
                                value: "quiz",
                                label: "Quiz",
                            },
                            {
                                value: "other",
                                label: "Other",
                            },
                        ]}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="Date">
                    <DatePicker
                        showTime
                        value={dayjs(date)}
                        format={"dddd, DD MMM YYYY, HH:mm"}
                        onChange={(value, dateString) => {
                            if (value === null) setDate(undefined);
                            else setDate(dayjs(value).format("YYYY-MM-DD HH:mm"));
                        }}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item label="Associated Dashboard">
                    <Select
                        value={dashboardId}
                        onChange={setDashboardId}
                        options={[{ value: null, label: "None of Listed" }].concat(
                            dashboardList?.map((ele) => {
                                return { value: ele?._id, label: ele?.name };
                            })
                        )}
                    />
                </Form.Item>
                <Form.Item label="Venue">
                    <Input value={venue} onChange={(e) => setVenue(e.target.value)} />
                </Form.Item>
                <Form.Item label="Weightage">
                    <Input value={weightage} onChange={(e) => setWeightage(e.target.value)} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
