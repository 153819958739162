import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllExamSchedule =
    (dashboardId = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "examSchedule", true);
        axios
            .get(`${url}/examSchedule/all`, { params: { dashboardId }, withCredentials: true })
            .then((result) => {
                dispatch({ type: "LOAD_EXAM_SCHEDULE", payload: result?.data?.data });
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "examSchedule", false));
    };

export const AddExamSchedule = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "examSchedule", true);
    axios
        .post(`${url}/examSchedule`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "examSchedule");
            if (result?.data?.success) {
                message.success("ExamSchedule Created");
                dispatch(FindAllExamSchedule());
            } else {
                message.error("Unable to create examSchedule");
            }
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "examSchedule", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "examSchedule", false));
};

export const UpdateExamSchedule = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "examSchedule", true);
    axios
        .put(`${url}/examSchedule`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "examSchedule");
            if (result.data?.success) message.success(result?.data?.msg);
            else message.error(result?.data?.msg);
            dispatch(FindAllExamSchedule());
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "examSchedule", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "examSchedule", false));
};

export const DeleteExamSchedule = (data, message, setDrawerOpen) => async (dispatch) => {
    dispatchLoading(dispatch, "examSchedule", true);
    axios
        .delete(`${url}/examSchedule`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "examSchedule");
            if (result?.data?.success) {
                message.success("ExamSchedule Deleted");
                dispatch(FindAllExamSchedule(data?.dashboardId));
                setDrawerOpen(false);
            } else {
                message.error("Unable to delete examSchedule");
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "examSchedule", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "examSchedule", false));
};
