import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllUser = () => async (dispatch) => {
    dispatchLoading(dispatch, "user", true);
    axios
        .get(`${url}/user/all`, { withCredentials: true })
        .then((result) => {
            if (result?.data?.success === true)
                dispatch({ type: "LOAD_USER_LIST", payload: result?.data?.data });
        })
        .catch((err) => console.log(err))
        .finally(() => dispatchLoading(dispatch, "user", false));
};
export const FindUser =
    (navigate = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "user", true);
        axios
            .get(`${url}/user`, { withCredentials: true })
            .then((result) => {
                if (result?.data?.success === true) {
                    dispatch({ type: "LOAD_USER", payload: result?.data?.data?.[0] });
                } else {
                    if (navigate !== null) navigate("/login");
                }
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "user", false));
    };

export const LoginUser = (data, message, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "user", true);
    axios
        .post(`${url}/user/auth`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "user", []);
            if (result?.data?.success) {
                dispatch({ type: "LOAD_USER", payload: result?.data?.data });
                message.success(result?.data?.msg);
                navigate("/dashboard");
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "user", err?.response?.data?.error);
            message.error(err?.response?.data?.msg);
        })
        .finally(() => dispatchLoading(dispatch, "user", false));
};
export const LogoutUser = (message, navigate) => async (dispatch) => {
    axios
        .delete(`${url}/user/auth`, { withCredentials: true })
        .then((result) => {
            dispatch({ type: "RESET_DASHBOARD" });
            dispatch({ type: "LOGOUT_USER" });
            message.success("Logout Successful");
            navigate("/", { replace: true });
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
        });
};
export const RegisterUser = (data, message, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "user", true);
    axios
        .post(`${url}/user`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "user");
            if (result?.data?.success) {
                dispatch({ type: "LOAD_USER", payload: result?.data?.data });
                message.success("Registration Successful");
                navigate("/dashboard");
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "user", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "user", false));
};

export const UpdateUser =
    (data, all = true) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "user", true);
        axios
            .put(`${url}/user`, data, { withCredentials: true })
            .then((result) => {
                if (all) dispatch(FindAllUser());
                else dispatch(FindUser());
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "user", false));
    };

export const DeleteUser =
    (data, all = true) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "user", true);
        axios
            .delete(`${url}/user`, { data, withCredentials: true })
            .then((result) => {
                if (all) dispatch(FindAllUser());
                else dispatch(FindUser());
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "user", false));
    };

export const ForgotUser = (data, message, showConfirmation) => async (dispatch) => {
    dispatchLoading(dispatch, "user", true);
    axios
        .post(`${url}/user/auth/forgot`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "user", []);
            if (result?.data?.success) {
                message.success(result?.data?.msg);
                showConfirmation();
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "user", err?.response?.data?.error);
            message.error(err?.response?.data?.msg);
        })
        .finally(() => dispatchLoading(dispatch, "user", false));
};

export const ResetUser = (data, message, navigate) => async (dispatch) => {
    dispatchLoading(dispatch, "user", true);
    axios
        .post(`${url}/user/auth/reset`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "user", []);
            if (result?.data?.success) {
                dispatch({ type: "LOAD_USER", payload: result?.data?.data });
                message.success(result?.data?.msg);
                navigate("/dashboard");
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "user", err?.response?.data?.error);
            message.error(err?.response?.data?.msg);
        })
        .finally(() => dispatchLoading(dispatch, "user", false));
};
