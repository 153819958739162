import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllFeedback = () => async (dispatch) => {
    dispatchLoading(dispatch, "feedback", true);
    axios
        .get(`${url}/feedback`, { withCredentials: true })
        .then((result) => {
            dispatch({ type: "LOAD_FEEDBACK", payload: result?.data?.data });
        })
        .catch((err) => console.log(err))
        .finally(() => dispatchLoading(dispatch, "feedback", false));
};

export const AddFeedback = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "feedback", true);
    axios
        .post(`${url}/feedback`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "feedback");
            if (result?.data?.success) {
                message.success("Feedback Submitted");
                dispatch(FindAllFeedback(data?.dashboardId));
            } else {
                message.error("Unable to submit your feedback");
            }
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "feedback", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "feedback", false));
};

export const DeleteFeedback = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "feedback", true);
    axios
        .delete(`${url}/feedback`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "feedback");
            dispatch(FindAllFeedback());
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "feedback", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "feedback", false));
};
