import React, { Suspense, useEffect, useState } from "react";
import { Outlet, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Layout, Menu, theme } from "antd";
import dayjs from "dayjs";
// Icons
import {
    SmileOutlined,
    ProjectOutlined,
    FileTextOutlined,
    BookOutlined,
    CalendarOutlined,
    UserOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
// Redux
import { LogoutUser } from "../Redux/User";

const { Header, Content, Sider } = Layout;

const items = [
    { key: "", icon: <ProjectOutlined />, label: "Dashboard" },
    { key: "assignment", icon: <FileTextOutlined />, label: "Assignments" },
    { key: "notes", icon: <BookOutlined />, label: "Notes" },
    { key: "examSchedule", icon: <CalendarOutlined />, label: "Exam Schedule" },
    { key: "profile", icon: <UserOutlined />, label: "Profile" },
    { key: "logout", icon: <LogoutOutlined />, label: "Logout" },
];

const UserIndex = () => {
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const { name } = useSelector((state) => state.user);
    const { userLoading } = useSelector((state) => state.loading);

    const { message } = App.useApp();

    const [collapsed, setCollapsed] = useState(false);
    const [currentTime, setCurrentTime] = useState(Date.now());

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const menuClickHanlder = ({ item, key, keyPath, domEvent }) => {
        if (key === "logout") dispatch(LogoutUser(message, navigate));
        else navigate(key);
    };

    useEffect(() => {
        if (name === null && !userLoading) navigate("/login");
    }, [userLoading]);

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 500);
        return () => clearInterval(timer);
    });

    return (
        <Layout style={{ minHeight: "100vh", overflow: "none" }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="text-white d-flex align-items-center justify-content-center mt-3">
                    <Link to="/dashboard">
                        <img
                            src={"/brand-no-bg.png"}
                            alt="Study Leo"
                            style={{ width: collapsed ? 70 : 150 }}
                        />
                    </Link>
                </div>
                <Menu
                    theme="dark"
                    defaultSelectedKeys={[
                        window.location?.pathname?.split("/")?.[
                            window.location?.pathname?.split("/")?.length - 1
                        ] === "dashboard"
                            ? ""
                            : window.location?.pathname?.split("/")?.[
                                  window.location?.pathname?.split("/")?.length - 1
                              ],
                    ]}
                    mode="inline"
                    items={items}
                    style={{ marginTop: 20 }}
                    onClick={menuClickHanlder}
                />
            </Sider>
            <Layout style={{ height: "100vh" }}>
                <Header
                    style={{
                        margin: 0,
                        background: colorBgContainer,
                    }}
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className="mt-3 pt-2">
                            <SmileOutlined className="me-2" />
                            {name}
                        </h6>
                        <h6 className="mt-3 pt-2">
                            {dayjs(currentTime).format(" hh:mm A, dddd, MMMM DD, YYYY")}
                        </h6>
                    </div>
                </Header>
                <Content style={{ margin: "16px", height: "100%" }}>
                    <div
                        style={{
                            height: "100%",
                            overflow: "auto",
                            background: colorBgContainer,
                            padding: 24,
                            paddingTop: 16,
                        }}
                    >
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default UserIndex;
