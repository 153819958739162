import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
    App,
    Button,
    DatePicker,
    Divider,
    Drawer,
    Empty,
    Input,
    Popconfirm,
    Typography,
} from "antd";
// Icon
import { ClockCircleOutlined, WarningFilled, CheckCircleFilled } from "@ant-design/icons";
// Redux
import { DeleteAssignment, FindAllAssignment, UpdateAssignment } from "../Redux/Assignment";
import { AssignmentModal } from "../Component/model";

const Assignment = () => {
    const { assignmentList } = useSelector((state) => state.dashboard);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [search, setSearch] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FindAllAssignment());
    }, []);

    return (
        <div>
            {/* Header Section */}
            <div className="d-md-flex align-items-center justify-content-between">
                <Typography.Title level={3} className="m-0 mb-3 mb-md-0">
                    Assignments
                </Typography.Title>
                <div className="d-flex align-items-center">
                    <Input.Search
                        placeholder="Search"
                        allowClear
                        onSearch={(value) => {
                            setSearch(value);
                            dispatch(FindAllAssignment(value));
                        }}
                    />
                    <Button type="primary" onClick={() => setModalOpen(true)} className="ms-2 px-4">
                        Add
                    </Button>
                </div>
            </div>
            {/* Header Section */}

            <Divider className="my-2" />

            {/* Assignment Content  */}
            <div className="row">
                {assignmentList && assignmentList?.length > 0 ? (
                    assignmentList.map((ele, index) => {
                        return (
                            <AssignmentCard data={ele} key={index} setDrawerOpen={setDrawerOpen} />
                        );
                    })
                ) : (
                    <Empty
                        description={
                            search?.length > 0
                                ? "No such Assignments found. Try searching for something else"
                                : "No Assignments, try adding new ones so you don't forget."
                        }
                    />
                )}
            </div>
            {/* Assignment Content  */}

            <AssignmentDetailDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            <AssignmentModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    );
};

const AssignmentDetailDrawer = ({ drawerOpen, setDrawerOpen }) => {
    const { drawerData } = useSelector((state) => state.dashboard);

    const { message } = App.useApp();

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [assignedOn, setAssignedOn] = useState(undefined);
    const [dueOn, setDueOn] = useState(undefined);
    const [completedOn, setCompletedOn] = useState(undefined);
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        setTitle(drawerData?.title);
        setDescription(drawerData?.description);
        setAssignedOn(drawerData?.assignedOn);
        setDueOn(drawerData?.dueOn);
        setCompletedOn(drawerData?.completedOn);
    }, [drawerData]);

    useEffect(() => {
        if (
            drawerData?.title !== title ||
            drawerData?.description !== description ||
            drawerData?.dueOn !== dueOn ||
            drawerData?.On !== assignedOn ||
            drawerData?.completedOn !== completedOn
        )
            dispatch(
                UpdateAssignment({
                    assignmentId: drawerData?._id,
                    title,
                    description,
                    dueOn,
                    assignedOn,
                    completedOn,
                })
            );
    }, [title, description, dueOn, assignedOn, completedOn]);

    return (
        <Drawer
            placement={"bottom"}
            width={10000}
            onClose={() => setDrawerOpen(false)}
            open={drawerOpen}
            closeIcon={false}
        >
            <Typography.Title
                editable={{
                    autoSize: true,
                    onChange: setTitle,
                }}
                level={3}
                style={{
                    margin: 0,
                    display: "flex",
                }}
            >
                {title}
            </Typography.Title>

            <Divider className="my-2" />
            <div className="row">
                <div className="col-8">
                    <Typography.Text
                        editable={{ onChange: setDescription }}
                        style={{ margin: 0 }}
                        placeholder="Enter description here"
                    >
                        {!description ? "Enter Description Here..." : description}
                    </Typography.Text>
                </div>

                <div className="col-4">
                    <div className="row gy-2 mb-2">
                        <Typography.Text className="col-6">Assigned On</Typography.Text>

                        <Typography.Text className="col-6">
                            {editMode ? (
                                <DatePicker
                                    value={dayjs(assignedOn)}
                                    format={"DD/MM/YYYY"}
                                    onChange={(value, dateString) => {
                                        if (null) setAssignedOn(undefined);
                                        setAssignedOn(dayjs(value).format("YYYY-MM-DD"));
                                    }}
                                    block
                                />
                            ) : (
                                dayjs(assignedOn).format("DD/MM/YYYY")
                            )}
                        </Typography.Text>

                        <Typography.Text className="col-6">Due On</Typography.Text>

                        <Typography.Text className="col-6">
                            {editMode ? (
                                <DatePicker
                                    value={dayjs(dueOn)}
                                    format={"DD/MM/YYYY"}
                                    onChange={(value, dateString) => {
                                        if (null) setDueOn(undefined);
                                        setDueOn(dayjs(value).format("YYYY-MM-DD"));
                                    }}
                                    block
                                />
                            ) : (
                                dayjs(dueOn).format("DD/MM/YYYY")
                            )}
                        </Typography.Text>

                        <Typography.Text className="col-6">Completed On</Typography.Text>

                        <Typography.Text className="col-6">
                            {editMode ? (
                                <DatePicker
                                    value={completedOn ? dayjs(completedOn) : ""}
                                    format={"DD/MM/YYYY"}
                                    onChange={(value, dateString) => {
                                        if (null) setCompletedOn(undefined);
                                        setCompletedOn(dayjs(value).format("YYYY-MM-DD"));
                                    }}
                                    block
                                />
                            ) : completedOn === undefined || completedOn === null ? (
                                "---"
                            ) : (
                                dayjs(completedOn).format("DD/MM/YYYY")
                            )}
                        </Typography.Text>

                        <Typography.Text className="col-6">Created On</Typography.Text>
                        <Typography.Text className="col-6">
                            {dayjs(drawerData?.createdAt).format("DD/MM/YYYY")}
                        </Typography.Text>

                        <Typography.Text className="col-6">Last Modified On</Typography.Text>
                        <Typography.Text className="col-6">
                            {drawerData?.updatedAt === null
                                ? "---"
                                : dayjs(drawerData?.updatedAt).format("DD/MM/YYYY")}
                        </Typography.Text>
                    </div>
                    <div className="d-flex">
                        <Button
                            type="primary"
                            onClick={() => {
                                if (editMode) setEditMode(false);
                                else setEditMode(true);
                            }}
                            block
                        >
                            {editMode ? "Turn off Edit" : "Edit"}
                        </Button>
                        <Button
                            type="primary"
                            className="bg-success ms-2"
                            onClick={() => {
                                setCompletedOn(dayjs(undefined).format("YYYY-MM-DD"));
                            }}
                            block
                        >
                            Mark as Complete
                        </Button>
                    </div>
                    <Popconfirm
                        title="Delete this Assignment"
                        description="Are you sure to delete this Assignment?"
                        onConfirm={() => {
                            dispatch(
                                DeleteAssignment(
                                    { assignmentId: drawerData?._id },
                                    message,
                                    setDrawerOpen
                                )
                            );
                            setDrawerOpen(false);
                        }}
                        onCancel={() => {}}
                        okText="DELETE"
                        cancelText="CANCEL"
                        className="mt-2"
                    >
                        <Button type="default" block danger>
                            Delete Task
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        </Drawer>
    );
};

const AssignmentCard = ({ data, setDrawerOpen }) => {
    const dispatch = useDispatch();

    const assignmentClickHandler = (assignment) => {
        dispatch({ type: "SET_DRAWER_DATA", payload: assignment });
        setDrawerOpen(true);
    };
    return (
        <div className="col-12 col-lg-6 col-xl-4">
            <div className="border rounded p-3 mb-3 " onClick={() => assignmentClickHandler(data)}>
                <Typography.Title level={4} className="m-0 p-0" ellipsis={{ length: 10 }}>
                    {data?.completedOn !== null ? (
                        <CheckCircleFilled className="me-2 small text-success" />
                    ) : new Date(data?.dueOn) < Date.now() ? (
                        <WarningFilled className="me-2 small text-danger" />
                    ) : (
                        <ClockCircleOutlined className="me-2 small" />
                    )}
                    {data?.title}
                </Typography.Title>
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <Typography.Text className="m-0 small">Assigned On</Typography.Text>
                        <Typography.Title level={5} className="m-0">
                            {dayjs(data?.assignedOn).format("ddd, DD MMM YYYY")}
                        </Typography.Title>
                    </div>
                    <div className="col-12 col-md-6">
                        <Typography.Text className="m-0 small">Due Date</Typography.Text>
                        <Typography.Title level={5} className="m-0">
                            {dayjs(data?.dueOn).format("ddd, DD MMM YYYY")}
                        </Typography.Title>
                    </div>
                    <div className="col-12 col-md-6">
                        <Typography.Text className="m-0 small">Completed On</Typography.Text>
                        <Typography.Title level={5} className="m-0">
                            {data?.completedOn === null
                                ? "---"
                                : dayjs(data?.completedOn).format("ddd, DD MMM YYYY")}
                        </Typography.Title>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Assignment;
