import { useState } from "react";
import { App, Button, FloatButton, Input, Modal, QRCode, Rate, Typography } from "antd";
// Icons
import {
    SettingOutlined,
    BugOutlined,
    BulbOutlined,
    ShareAltOutlined,
    StarOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AddBugs } from "../Redux/Bugs";
import { AddFeedback } from "../Redux/Feedback";

export const FloatButtonHelper = ({ darkMode, setDarkMode }) => {
    const { message } = App.useApp();

    const [modalBugOpen, setModalBugOpen] = useState(false);
    const [modalShareOpen, setModalShareOpen] = useState(false);
    const [modalFeedBackOpen, setModalFeedBackOpen] = useState(false);

    const [bugDescription, setBugDescription] = useState("");

    const [fbExperience, setFbExperience] = useState(1);
    const [fbUsability, setFbUsablitity] = useState(1);
    const [fbUsefulness, setFbUsefulness] = useState(1);
    const [fbRecommend, setFbRecommend] = useState(1);

    const dispatch = useDispatch();

    return (
        <>
            <FloatButton.Group
                trigger="click"
                type="primary"
                style={{ right: 20, bottom: 20 }}
                icon={<SettingOutlined />}
            >
                <FloatButton
                    icon={<StarOutlined />}
                    tooltip="Feedback"
                    onClick={() => setModalFeedBackOpen(true)}
                />
                <FloatButton
                    icon={<ShareAltOutlined />}
                    tooltip="Share"
                    onClick={() => setModalShareOpen(true)}
                />
                <FloatButton
                    icon={<BugOutlined />}
                    tooltip="Report Bug"
                    onClick={() => setModalBugOpen(true)}
                />
                <FloatButton
                    icon={<BulbOutlined />}
                    tooltip="Toggle Dark Mode"
                    onClick={() => {
                        localStorage.setItem("mode", darkMode === "dark" ? "light" : "dark");
                        setDarkMode(darkMode === "dark" ? "light" : "dark");
                    }}
                />
            </FloatButton.Group>

            {/* Bug Modal */}
            <Modal
                title="Report Bug"
                open={modalBugOpen}
                onOk={() => {
                    dispatch(AddBugs({ description: bugDescription }, message));
                    setBugDescription("");
                    setModalBugOpen(false);
                }}
                onCancel={() => {
                    setBugDescription("");
                    setModalBugOpen(false);
                }}
                okText={"Submit"}
            >
                <p>
                    Please describe the problem you faced, we will make it a priority to resolve it
                    as soon as possible.
                </p>

                <Input.TextArea
                    placeholder="Describe bug here..."
                    value={bugDescription}
                    onChange={(e) => setBugDescription(e.target.value)}
                    rows={4}
                />
            </Modal>
            {/* Bug Modal */}

            {/* Share Modal */}
            <Modal
                open={modalShareOpen}
                footer={[]}
                onCancel={() => {
                    setModalShareOpen(false);
                }}
            >
                <div className="p-4 text-center">
                    <Typography.Title level={2}>Invite your friends over!</Typography.Title>
                    <div className="d-flex justify-content-center">
                        <QRCode value={"http://localhost:3000/"} size={256} bordered={false} />
                    </div>

                    <Typography.Text>
                        There is saying that goes, "Sharing is Caring".
                    </Typography.Text>
                </div>
            </Modal>
            {/* Share Modal */}

            {/* Feedback Modal */}
            <Modal
                open={modalFeedBackOpen}
                footer={[]}
                onCancel={() => {
                    setModalFeedBackOpen(false);
                }}
            >
                <div className="px-4 py-2">
                    <Typography.Title level={2}>Share your experience!</Typography.Title>

                    <Typography.Title className="mt-2" level={5}>
                        Overall UI Experience
                    </Typography.Title>
                    <Rate value={fbExperience} onChange={setFbExperience} allowClear={false} />

                    <Typography.Title className="mt-2" level={5}>
                        Ease of Use
                    </Typography.Title>
                    <Rate value={fbUsability} onChange={setFbUsablitity} allowClear={false} />

                    <Typography.Title className="mt-2" level={5}>
                        How useful is this product to you?
                    </Typography.Title>
                    <Rate value={fbUsefulness} onChange={setFbUsefulness} allowClear={false} />

                    <Typography.Title className="mt-2" level={5}>
                        How likely are you to recommend to others?
                    </Typography.Title>
                    <Rate value={fbRecommend} onChange={setFbRecommend} allowClear={false} />

                    <Button
                        type="primary"
                        size={"large"}
                        className="d-block mx-auto mt-3 mb-0"
                        onClick={() => {
                            dispatch(
                                AddFeedback(
                                    {
                                        experience: fbExperience,
                                        usability: fbUsability,
                                        usefulness: fbUsefulness,
                                        recommendation: fbRecommend,
                                    },
                                    message
                                )
                            );
                            setModalFeedBackOpen(false);
                        }}
                    >
                        Submit
                    </Button>
                </div>
            </Modal>
            {/* Feedback Modal */}
        </>
    );
};
