import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllTask = (dashboardId) => async (dispatch) => {
    dispatchLoading(dispatch, "task", true);
    axios
        .get(`${url}/task/all`, { params: { dashboardId }, withCredentials: true })
        .then((result) => {
            dispatch({ type: "LOAD_TASK", payload: result?.data?.data });
        })
        .catch((err) => console.log(err))
        .finally(() => dispatchLoading(dispatch, "task", false));
};

export const AddTask = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "task", true);
    axios
        .post(`${url}/task`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "task");
            if (result?.data?.success) {
                message.success("Task Created");
                dispatch(FindAllTask(data?.dashboardId));
            } else message.error("Unable to create task");
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "task", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "task", false));
};

export const UpdateTask = (data) => async (dispatch) => {
    dispatchLoading(dispatch, "task", true);
    axios
        .put(`${url}/task`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "task");
            dispatch(FindAllTask(data?.dashboardId));
        })
        .catch((err) => {
            if (err?.response?.status === 400)
                dispatchError(dispatch, "task", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "task", false));
};

export const DeleteTask = (data, message) => async (dispatch) => {
    dispatchLoading(dispatch, "task", true);
    axios
        .delete(`${url}/task`, { data, withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "task");
            if (result?.data?.success) {
                message.success("Task Deleted");
                dispatch(FindAllTask(data?.dashboardId));
            } else message.error("Unable to delete task");
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "task", err?.response?.data?.error);
        })
        .finally(() => dispatchLoading(dispatch, "task", false));
};
