import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { App, Button, Card, Typography } from "antd";
// Icon
import { UserOutlined } from "@ant-design/icons";
// Redux
import { RegisterUser } from "../Redux/User";
import { InputBox } from "../Component/Input";
import { dispatchError } from "../utils";
import { TypeAnimation } from "react-type-animation";

const Register = () => {
    const { userLoading } = useSelector((state) => state.loading);
    const { userError } = useSelector((state) => state.error);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const registerHandler = () => {
        dispatch(RegisterUser({ name, email, password }, message, navigate));
    };
    return (
        <div className="vh-100 bg-warning overflow-auto">
            <div className="d-flex align-items-center justify-content-center my-auto flex-column my-5 py-5">
                <img src={"/brand.png"} alt="Study Leo" className="my-3" style={{ width: 200 }} />

                <Card style={{ minWidth: 350, zIndex: 1 }} className="shadow mb-3">
                    <Typography.Title level={1} className="text-center m-0 p-0">
                        <UserOutlined />
                    </Typography.Title>
                    <Typography.Title level={3} className="text-center m-0 mb-2">
                        Register
                    </Typography.Title>

                    <InputBox
                        label={"Name"}
                        type={"text"}
                        value={name}
                        setValue={setName}
                        loading={userLoading}
                        error={userError?.name}
                    />
                    <InputBox
                        label={"Email"}
                        type={"email"}
                        value={email}
                        setValue={setEmail}
                        loading={userLoading}
                        error={userError?.email}
                    />
                    <InputBox
                        label={"Password"}
                        type={"password"}
                        value={password}
                        setValue={setPassword}
                        loading={userLoading}
                        error={userError?.password}
                    />

                    <Button
                        type="primary"
                        block
                        className="mb-3"
                        size="large"
                        onClick={registerHandler}
                        disabled={userLoading}
                        loading={userLoading}
                    >
                        Register
                    </Button>
                    <Link
                        to="/login"
                        className="text-decoration-none"
                        onClick={() => dispatchError(dispatch, "user")}
                    >
                        <p className="text-center m-0">Already Have Account? Login</p>
                    </Link>
                </Card>
                <TypeAnimation
                    sequence={[
                        "Sign up today, it's free, secure, and safe. Exciting experiences are just around the corner.",
                        500, // Waits 1s
                        "Enroll now, at no cost, with complete security and peace of mind. Embark on your next adventure.",
                        500, // Waits 2s
                        "Join us now, absolutely free and with utmost security. Thrilling adventures are waiting for you.",
                        () => {},
                    ]}
                    wrapper="span"
                    cursor={true}
                    repeat={Infinity}
                    style={{
                        fontSize: "14px",
                        display: "inline-block",
                        fontWeight: "bold",
                    }}
                />
            </div>
        </div>
    );
};

export default Register;
