import { configureStore } from "@reduxjs/toolkit";

/** Reducers */
const userReducer = (state = { name: null }, action) => {
    switch (action.type) {
        case "LOAD_USER":
            return { ...state, ...action.payload };
        case "LOGOUT_USER":
            return { name: null };
        default:
            return state;
    }
};

const dashboardReducer = (
    state = {
        dashboardList: null,
        taskList: null,
        drawerData: null,
    },
    action
) => {
    switch (action.type) {
        case "LOAD_DASHBOARD":
            return { ...state, dashboardList: action.payload };
        case "LOAD_TASK":
            return { ...state, taskList: action.payload };
        case "LOAD_EXAM_SCHEDULE":
            return { ...state, examScheduleList: action.payload };
        case "LOAD_NOTES":
            return { ...state, notesList: action.payload };
        case "LOAD_ASSIGNMENT":
            return { ...state, assignmentList: action.payload };
        case "SET_DRAWER_DATA":
            return { ...state, drawerData: action.payload };
        case "RESET_DASHBOARD":
            return {
                dashboardList: null,
                taskList: null,
                drawerData: null,
            };
        default:
            return state;
    }
};

const adminReducer = (
    state = {
        stat: null,
        bugsList: null,
        userList: null,
        feedbackList: null,
    },
    action
) => {
    switch (action.type) {
        case "LOAD_DASHBOARD_COUNT":
            return { ...state, stat: action.payload };
        case "LOAD_USER_LIST":
            return { ...state, userList: action.payload };
        case "LOAD_BUGS":
            return { ...state, bugsList: action.payload };
        case "LOAD_FEEDBACK":
            return { ...state, feedbackList: action.payload };

        case "RESET_DASHBOARD":
            return {
                stat: null,
                bugsList: null,
                userList: null,
                feedbackList: null,
            };
        default:
            return state;
    }
};

const loadingReducer = (
    state = {
        userLoading: false,
        dashboardLoading: false,
    },
    action
) => {
    switch (action.type) {
        case "SET_LOADING":
            return Object.assign({}, state, {
                [`${action.payload.scope}Loading`]: action.payload.value,
            });
        default:
            return state;
    }
};

const errorReducer = (state = {}, action) => {
    switch (action.type) {
        case "SET_ERROR":
            if (!action.payload?.value?.length)
                return { ...state, [`${action.payload?.scope}Error`]: {} };
            let temp = {};
            action.payload?.value?.forEach((ele) => {
                temp[`${ele?.path}`] = ele?.msg;
            });
            return Object.assign({}, state, {
                [`${action.payload?.scope}Error`]: temp,
            });

        default:
            return state;
    }
};

const store = configureStore({
    reducer: {
        user: userReducer,
        dashboard: dashboardReducer,
        loading: loadingReducer,
        admin: adminReducer,
        error: errorReducer,
    },
});

export default store;
