export const InputBox = ({ label, type, value, setValue, loading, error }) => {
    return (
        <div className="mb-2">
            <label className="mb-2" htmlFor={label}>
                {label}
            </label>
            <input
                className="form-control"
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                disabled={loading}
            />
            <span className="m-0 small text-danger">&nbsp;{error}</span>
        </div>
    );
};
