import axios from "axios";

import { dispatchError, dispatchLoading, url } from "../utils";

export const FindAllAssignment =
    (search = "", dashboardId = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "assignment", true);
        axios
            .get(`${url}/assignment/all`, {
                params: { search, dashboardId },
                withCredentials: true,
            })
            .then((result) => {
                dispatch({ type: "LOAD_ASSIGNMENT", payload: result?.data?.data });
            })
            .catch((err) => console.log(err))
            .finally(() => dispatchLoading(dispatch, "assignment", false));
    };

export const AddAssignment = (data, message, closeHandler) => async (dispatch) => {
    dispatchLoading(dispatch, "assignment", true);
    axios
        .post(`${url}/assignment`, data, { withCredentials: true })
        .then((result) => {
            dispatchError(dispatch, "assignment");
            if (result?.data?.success) {
                message.success("Assignment Created");
                dispatch(FindAllAssignment());
                closeHandler();
            } else message.error(result?.data?.msg);
        })
        .catch((err) => {
            message.error(err?.response?.data?.msg);
            if (err?.response?.status === 400)
                dispatchError(dispatch, "assignment", err?.response?.data?.error);
        })

        .finally(() => dispatchLoading(dispatch, "assignment", false));
};

export const UpdateAssignment =
    (data, dashboardId = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "assignment", true);
        axios
            .put(`${url}/assignment`, data, { withCredentials: true })
            .then((result) => {
                dispatchError(dispatch, "assignment");
                if (dashboardId) dispatch(FindAllAssignment(null, dashboardId));
                dispatch(FindAllAssignment());
            })
            .catch((err) => {
                if (err?.response?.status === 400)
                    dispatchError(dispatch, "assignment", err?.response?.data?.error);
            })
            .finally(() => dispatchLoading(dispatch, "assignment", false));
    };

export const DeleteAssignment =
    (data, message, dashboardId = null) =>
    async (dispatch) => {
        dispatchLoading(dispatch, "assignment", true);
        axios
            .delete(`${url}/assignment`, { data, withCredentials: true })
            .then((result) => {
                if (result?.data?.success) {
                    message.success("Assignment Deleted");
                    if (dashboardId) dispatch(FindAllAssignment(null, dashboardId));
                    dispatch(FindAllAssignment());
                } else message.error("Unable to delete Assignment");
            })
            .catch((err) => message.error(err?.response?.data?.msg))
            .finally(() => dispatchLoading(dispatch, "assignment", false));
    };
