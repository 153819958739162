import { lazy, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { App as AppWrapper, ConfigProvider, theme } from "antd";
import { useDispatch } from "react-redux";
// Component
import { FloatButtonHelper } from "./Component/FloatButtonHelper";
// Redux
import { FindUser } from "./Redux/User";

// User Views
import UserIndex from "./User/Index";
import Dashboard from "./User/Dashboard";
import Profile from "./User/Profile";
import Assignment from "./User/Assignment";
import Notes from "./User/Notes";
import ExamSchedule from "./User/Exams";
// Auth View
import Login from "./Auth/Login";
import Register from "./Auth/Register";

// Static
import Index from "./Views/Index";
import Error404 from "./Views/Error404";

// Admin Views
import AdminIndex from "./Admin/Index";
import Forgot from "./Auth/Forgot";
import Reset from "./Auth/Reset";

// Lazy User View
const Tasks = lazy(() => import("./User/Tasks"));
const NotesPage = lazy(() => import("./User/NotesPage"));
// Lazy Admin View
const Bugs = lazy(() => import("./Admin/Bugs"));
const User = lazy(() => import("./Admin/User"));
const AdminDashboard = lazy(() => import("./Admin/Dashboard"));
const Feedback = lazy(() => import("./Admin/Feedback"));

const App = () => {
    const { darkAlgorithm, defaultAlgorithm } = theme;

    const [darkMode, setDarkMode] = useState("light");

    useEffect(() => {
        let mode = localStorage.getItem("mode");
        if (mode) setDarkMode(mode);
        else setDarkMode("light");
    }, []);

    return (
        <AppWrapper>
            <ConfigProvider
                theme={{
                    algorithm: darkMode === "dark" ? darkAlgorithm : defaultAlgorithm,
                }}
            >
                <Routes>
                    <Route path="/dashboard" element={<UserIndex />}>
                        <Route path="" element={<Dashboard />} />
                        <Route path=":dashboardId" element={<Tasks />} />
                        <Route path="assignment" element={<Assignment />} />
                        <Route path="notes" element={<Notes />} />
                        <Route path="notes/:notesId" element={<NotesPage />} />
                        <Route path="examSchedule" element={<ExamSchedule />} />
                        <Route path="profile" element={<Profile />} />
                    </Route>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/forgot" element={<Forgot />} />
                    <Route path="/reset/:token" element={<Reset />} />

                    <Route path="/" element={<Index />} />
                    <Route path="*" element={<Error404 />} />

                    <Route path="/admin" element={<AdminIndex />}>
                        <Route path="" element={<AdminDashboard />} />
                        <Route path="bugs" element={<Bugs />} />
                        <Route path="user" element={<User />} />
                        <Route path="feedback" element={<Feedback />} />
                    </Route>
                </Routes>
                {/* Helper */}
                <DataLoadHelper />
                <FloatButtonHelper darkMode={darkMode} setDarkMode={setDarkMode} />
            </ConfigProvider>
        </AppWrapper>
    );
};

const DataLoadHelper = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(FindUser());
    }, []);

    return <></>;
};

export default App;
